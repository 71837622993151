var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12"},[(!_vm.showReferralFollowup)?_c('card',{staticClass:"card",attrs:{"title":"Referral Pathways Table"}},[_c('table',{staticClass:"table"},[_c('thead',[_c('th',[_vm._v("Case Id")]),_c('th',[_vm._v("Referral Unit")]),_c('th',[_vm._v("Location")]),_c('th',[_vm._v("Type")]),_c('th',[_vm._v("Referred From")]),_c('th',[_vm._v("Referred To")]),_c('th',[_vm._v("Created")]),_c('th',[_vm._v("More")])]),_c('tbody',[(_vm.isFetchingReferralPathways)?_c('tr',[_c('td',{staticStyle:{"text-align":"center"},attrs:{"colspan":"8"}},[_c('i',{staticClass:"fa fa-spinner fa-spin fa-fw fa-2x"})])]):(!_vm.referralPathwayDetails.length)?_c('tr',[_c('td',{staticStyle:{"text-align":"center"},attrs:{"colspan":"8"}},[_vm._v(" No Referral Pathways Found ")])]):_vm._l((_vm.referralPathwayDetails),function(item,idx){return _c('tr',{key:idx},[_c('td',[_vm._v(_vm._s(item.caseId))]),_c('td',[_vm._v(_vm._s(item.referralUnitName))]),_c('td',[_vm._v(_vm._s(item.referralLocationName))]),_c('td',[_vm._v(_vm._s(item.referralType))]),_c('td',[_vm._v(_vm._s(_vm.handleGetReferralUnit(item.fromReferralId)))]),_c('td',[_vm._v(_vm._s(_vm.handleGetReferralUnit(item.toReferralId)))]),_c('td',[_vm._v(_vm._s(_vm.formatTimestamp(item.created)))]),_c('td',{staticStyle:{"text-align":"center"}},[_c('button',{staticClass:"formButton",attrs:{"type":"button","round":""},on:{"click":function($event){return _vm.handleShowTab(
                  item.referralUnitName,
                  item.referralLocationName,
                  item.referralUnitId,
                  item.referralLocationId,
                  item.caseId,
                  item.referralPathwayId
                )}}},[_c('i',{staticClass:"ti-new-window icon"})])])])})],2)])]):_vm._e(),(_vm.showReferralFollowup)?_c('div',[_c('referral-pathway-followup',{attrs:{"referralUnitName":_vm.referralUnitName,"referralLocationName":_vm.referralLocationName,"referralUnitId":_vm.referralUnitId,"referralLocationId":_vm.referralLocationId,"caseId":_vm.caseId,"referralPathwayId":_vm.referralPathwayId},on:{"handleClose":_vm.handleCloseTab}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }