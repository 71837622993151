import { fetchData } from '@/utils/api/analytics';
import {getCaseByTagAndDate, getSurvivorAgeByDate} from "@/utils/api/cases";

const defaultState = {
    callCount: null,
    isFetchingCallCount: false,

    callDurationBar: null,
    isFetchingCallDurationBar: false,

    callDuration: null,
    isFetchingCallDuration: false,

    pieCases: null,
    isFetchingPieCases: false,

    cases: null,
    isFetchingCases: false,

    reportedCasesBar: null,
    isFetchingReportedCasesBar: false,

    reportedCasesLine: null,
    isFetchingReportedCasesLine: false,

    barCases: null,
    isFetchingBarCases: false,

    pieCalls: null,
    isFetchingPieCalls: false,

    calls: null,
    isFetchingCalls: false,

    barCalls: null,
    isFetchingBarCalls: false,

    survivorAgeGroupBar: [],
    isFetchingSurvivorAgeGroupBar: false,

    survivorAgeGroup: [],
    isFetchingSurvivorAgeGroup: false,

    isFetchingReferralPathwaysChart: false,
    referralPathwaysChart:null,

    isFetchingReferralPathwaysBarChart: false,
    referralPathwaysBarChart:null,
};

const mutations = {
    setIsFetchingCallCount(state, status) {
        state.isFetchingCallCount = status;
    },
    setCallCount(state, callCount) {
        state.callCount = callCount;
    },

    setIsFetchingCallDurationBar(state, status) {
       state.isFetchingCallDurationBar = status;
    },
    setCallDurationBar(state, callDuration) {
       state.callDurationBar = callDuration;
    },

    setIsFetchingCallDuration(state, status) {
        state.isFetchingCallDuration = status;
    },
    setCallDuration(state, callDuration) {
        state.callDuration = callDuration;
    },

    setIsFetchingPieCases(state, status) {
        state.isFetchingPieCases = status;
    },
    setPieCases(state, pieCases) {
        state.pieCases = pieCases;
    },

    setIsFetchingCases(state, status) {
        state.isFetchingCases = status;
    },
    setCases(state, cases) {
        state.cases = cases;
    },

    setIsFetchingReportedCasesBar(state, status) {
       state.isFetchingReportedCasesBar = status;
    },
    setReportedCasesBar(state, cases) {
       state.reportedCasesBar = cases;
    },

    setIsFetchingReportedCasesLine(state, status) {
        state.isFetchingReportedCasesLine = status;
    },
    setReportedCasesLine(state, cases) {
       state.reportedCasesLine = cases;
    },

    setIsFetchingBarCases(state, status) {
        state.isFetchingBarCases = status;
    },
    setBarCases(state, barCases) {
        state.barCases = barCases;
    },

    setIsFetchingPieCalls(state, status) {
        state.isFetchingPieCalls = status;
    },
    setPieCalls(state, pieCalls) {
        state.pieCalls = pieCalls;
    },

    setIsFetchingCalls(state, status) {
        state.isFetchingCalls = status;
    },
    setCalls(state, calls) {
        state.calls = calls;
    },

    setIsFetchingBarCalls(state, status) {
        state.isFetchingBarCalls = status;
    },
    setBarCalls(state, barCalls) {
        state.barCalls = barCalls;
    },

    setSurvivorAgeGroupBar(state, reports) {
       state.survivorAgeGroupBar = reports;
    },
    setIsFetchingSurvivorAgeGroupBar(state, status) {
      state.isFetchingSurvivorAgeGroupBar = status;
    },

    setSurvivorAgeGroup(state, reports) {
      state.survivorAgeGroup = reports;
    },
    setIsFetchingSurvivorAgeGroup(state, status) {
      state.isFetchingSurvivorAgeGroup = status;
    },

    setIsFetchingReferralPathwaysChart(state, status) {
      state.isFetchingReferralPathwaysChart = status;
    },
    setReferralPathwaysChart(state, status) {
      state.referralPathwaysChart = status;
    },

    setIsFetchingReferralPathwaysBarChart(state, status) {
      state.isFetchingReferralPathwaysBarChart = status;
    },
    setReferralPathwaysBarChart(state, status) {
      state.referralPathwaysBarChart = status;
    },
};

const actions = {
    async fetchCallCount({ commit }, data) {
        try {
            commit('setIsFetchingCallCount', true);
            const results = await fetchData(data);
            commit('setCallCount', results);
            commit('setIsFetchingCallCount', false);
        } catch (ex) {
            commit('setIsFetchingCallCount', false);
            throw ex;
        }
    },

    async fetchCallDurationBar({ commit }, data) {
        try {
            commit('setIsFetchingCallDurationBar', true);
            const results = await fetchData(data);
            commit('setCallDurationBar', results);
            commit('setIsFetchingCallDurationBar', false);
        } catch (ex) {
            commit('setIsFetchingCallDurationBar', false);
            throw ex;
        }
    },

    async fetchCallDuration({ commit }, data) {
        try {
            commit('setIsFetchingCallDuration', true);
            const results = await fetchData(data);
            commit('setCallDuration', results);
            commit('setIsFetchingCallDuration', false);
        } catch (ex) {
            commit('setIsFetchingCallDuration', false);
            throw ex;
        }
    },

    async fetchPieCases({ commit }, data) {
        try {
            commit('setIsFetchingPieCases', true);
            const results = await fetchData(data);
            commit('setPieCases', results);
            commit('setIsFetchingPieCases', false);
        } catch (ex) {
            commit('setIsFetchingPieCases', false);
            throw ex;
        }
    },

    async fetchCases({ commit }, data) {
        try {
            commit('setIsFetchingCases', true);
            const results = await fetchData(data);
            // console.log("fetchCases result", results);
            
            commit('setCases', results);
            commit('setIsFetchingCases', false);
        } catch (ex) {
            commit('setIsFetchingCases', false);
            throw ex;
        }
    },

    async fetchReportedCasesBar({ commit }, data) {
        try {
            commit('setIsFetchingReportedCasesBar', true);
            const results = await getCaseByTagAndDate(data);
            commit('setReportedCasesBar', results);
            commit('setIsFetchingReportedCasesBar', false);
        } catch (ex) {
            commit('setIsFetchingReportedCasesBar', false);
            throw ex;
        }
    },

    async fetchReportedCasesLine({ commit }, data) {
        try {
            commit('setIsFetchingReportedCasesLine', true);
            const results = await getCaseByTagAndDate(data);
            commit('setReportedCasesLine', results);
            commit('setIsFetchingReportedCasesLine', false);
        } catch (ex) {
            commit('setIsFetchingReportedCasesLine', false);
            throw ex;
        }
    },

    async fetchBarCases({ commit }, data) {
        try {
            commit('setIsFetchingBarCases', true);
            const results = await fetchData(data);
            commit('setBarCases', results);
            commit('setIsFetchingBarCases', false);
        } catch (ex) {
            commit('setIsFetchingBarCases', false);
            throw ex;
        }
    },

    async fetchPieCalls({ commit }, data) {
        try {
            commit('setIsFetchingPieCalls', true);
            const results = await fetchData(data);
            commit('setPieCalls', results);
            commit('setIsFetchingPieCalls', false);
        } catch (ex) {
            commit('setIsFetchingPieCalls', false);
            throw ex;
        }
    },

    async fetchCalls({ commit }, data) {
        try {
            commit('setIsFetchingCalls', true);
            const results = await fetchData(data);
            commit('setCalls', results);
            commit('setIsFetchingCalls', false);
        } catch (ex) {
            commit('setIsFetchingCalls', false);
            throw ex;
        }
    },

    async fetchBarCalls({ commit }, data) {
        try {
            commit('setIsFetchingBarCalls', true);
            const results = await fetchData(data);
            commit('setBarCalls', results);
            commit('setIsFetchingBarCalls', false);
        } catch (ex) {
            commit('setIsFetchingBarCalls', false);
            throw ex;
        }
    },

  async fetchSurvivorAgeGroupBar({ commit }, data) {
    try {
      commit('setIsFetchingSurvivorAgeGroupBar', true);
      const results = await getSurvivorAgeByDate(data);
      commit('setSurvivorAgeGroupBar', results);
      commit('setIsFetchingSurvivorAgeGroupBar', false);
    } catch (ex) {
      commit('setIsSurvivorAgeByDateBar', false);
      throw ex;
    }
  },

  async fetchSurvivorAgeGroup({ commit }, data) {
    try {
      commit('setIsFetchingSurvivorAgeGroup', true);
      const results = await getSurvivorAgeByDate(data);
      commit('setSurvivorAgeGroup', results);
      commit('setIsFetchingSurvivorAgeGroup', false);
    } catch (ex) {
      commit('setIsSurvivorAgeByDate', false);
      throw ex;
    }
  },

  async fetchReferralPathwaysChart({ commit }, data) {
    try {
      commit('setIsFetchingReferralPathwaysChart', true);
      const results = await fetchData(data);
      commit('setReferralPathwaysChart', results);
      commit('setIsFetchingReferralPathwaysChart', false);
    } catch (ex) {
      commit('setIsFetchingReferralPathwaysChart', false);
      throw ex;
    }
  },

  async fetchReferralPathwaysBarChart({ commit }, data) {
    try {
      commit('setIsFetchingReferralPathwaysBarChart', true);
      const results = await fetchData(data);
      commit('setReferralPathwaysBarChart', results);
      commit('setIsFetchingReferralPathwaysBarChart', false);
    } catch (ex) {
      commit('setIsFetchingReferralPathwaysBarChart', false);
      throw ex;
    }
  }
};

export default {
    namespaced: true,
    state: defaultState,
    mutations,
    actions,
};
