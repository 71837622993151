<template>
  <div class="row">
    <router-view></router-view>
  </div>
</template>
<script>
import EditProfileForm from "./Settings/EditProfileForm.vue";
import UserCard from "./Settings/UserCard.vue";
import MembersCard from "./Settings/MembersCard.vue";
import ChangePassword from "./Settings/ChangePassword.vue";
import CreateReferralUnit from "./Settings/CreateReferralUnit.vue";
import UpdateReferralUnit from "./Settings/UpdateReferralUnit.vue";
import { getCounsellor } from "@/utils/auth";
export default {
  data() {
    return {
      counsellor: {},
    };
  },
  components: {
    ChangePassword,
    EditProfileForm,
    UserCard,
    MembersCard,
    CreateReferralUnit,
    UpdateReferralUnit,
  },
  async mounted() {
    this.counsellor = getCounsellor();
    console.log(this.counsellor);
  },
};
</script>
<style lang="scss" scoped></style>
