<template>
    <div>
        <card v-if="page === 1" class="card" title="Update Referral unit"
            subTitle="Select a referral unit you would like to update">
            <form>
                <div v-if="selectedUnitError">
                    <p class="text-danger">
                        {{ selectedUnitError }}
                    </p>
                </div>
                <!-- Loop through units -->
                <div v-for="unit in units" :key="unit.name" class="form-group">
                    <!-- Unit Checkbox -->
                    <label>
                        <input type="radio" v-model="selectedUnit" :value="unit.name" />
                        {{ unit.name }}
                    </label>
                </div>

                <div v-if="selectedUnitError">
                    <span v-if="selectedUnitError" class="text-danger">
                        {{ selectedUnitError }}
                    </span>
                </div>

                <p-button type="success" style="margin-left: auto; display: block" round
                    @click.native.prevent="handleSelectedUnit">Select</p-button>
            </form>
        </card>
        <card v-if="page === 2" class="card" title="Update Referral unit"
            subTitle="Select a referral unit you would like to update">
            <form>
                <div class="row">
                    <div class="col-md-12">
                        <fg-input type="text" label="Referral Unit Name" v-model="selectedUnitName" required readonly>
                        </fg-input>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <fg-input type="text" label="New Referral Unit Name" required v-model="newName">
                        </fg-input>
                        <span v-if="error" class="text-danger">{{ error }}</span>
                    </div>
                </div>

                <div class="col-md-12 row">
                    <p-button type="info" round @click.native.prevent="page = 1"
                        style="margin-left: 15px; display: block">
                        Back
                    </p-button>
                    <p-button type="success" round @click.native.prevent="submitForm"
                        style="margin-left: auto; display: block">
                        Update
                    </p-button>
                </div>
            </form>
        </card>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import swal from "sweetalert";
export default {
    props: {},
    data() {
        return {
            page: 1,
            selectedUnitError: null,
            error: null,
            units: [],
            finalData: null,
            selectedUnit: "",
            selectedUnitName: null,
            newName: "",
        };
    },
    methods: {
        ...mapActions("cases", [
            "fetchAllReferralUnit",
            "updateReferralUnit"
        ]),
        handleCloseForm() {
            this.$emit("handleClose");
        },
        handleSelectedUnit() {
            if (!this.selectedUnit) {
                this.selectedUnitError = "Select a referral unit";
                return;
            }
            this.selectedUnitName = this.selectedUnit;
            this.page = 2;
        },
        resetForm() {
            this.page = 1;
            this.selectedUnit = "";
            this.selectedUnitName = null;
            this.newName = "";
        },
        validateForm() {
            this.error = null;
            if (!this.newName) {
                this.error = "New Referral Unit Name is required";
                return false;
            }
            return true;
        },
        async submitForm() {
            if (this.validateForm()) {
                try {
                    const { $router, units, newName, selectedUnitName, updateReferralUnit } = this;
                    swal({
                        title: "Complete Submission",
                        text: "Are You sure you want to update Referral Unit?",
                        buttons: {
                            cancel: true,
                            confirm: true,
                        },
                    }).then((results) => {
                        if (results) {
                            const selectedUnit = units.find(unit => unit.name === selectedUnitName);

                            if (selectedUnit) {
                                updateReferralUnit({
                                    name: newName,
                                    referralUnitId: selectedUnit.id,
                                }).then(() => {
                                    this.$notify({
                                        message: "Referral Unit Updated Successfully",
                                        icon: "ti-check-box",
                                        horizontalAlign: "center",
                                        verticalAlign: "top",
                                        type: "success",
                                    });
                                    this.resetForm();
                                });
                            } else {
                                this.$notify({
                                    message: "Failed to update referral unit",
                                    icon: "ti-alert",
                                    horizontalAlign: "center",
                                    verticalAlign: "top",
                                    type: "danger",
                                });
                                this.resetForm();
                            }
                        }
                    });
                } catch (error) {
                    this.$notify({
                        message: "Failed to update referral unit",
                        icon: "ti-alert",
                        horizontalAlign: "center",
                        verticalAlign: "top",
                        type: "danger",
                    });
                }
            }
        },
    },
    computed: {
        ...mapState("cases", ["allReferralUnits"]),
    },
    mounted() {
        this.fetchAllReferralUnit()
            .then(() => {
                this.units = this.allReferralUnits.units;
            })
            .catch((error) => {
                console.error("Failed to fetch referral units:", error);
            });
    },
};
</script>

<style scoped>
/* Add your styles here */
.full {
    width: 100%;
}
</style>