export const utilsDemodate = [
  {
    id: "a240aeb8-a725-4fbe-8eb8-fe620f2f7f21",
    location: [],
    name: "Medical",
    subUnits: [
      {
        id: "c676c409-1064-4777-89bc-e0ae70d742ba",
        location: [
          {
            id: "022574ed-169f-4400-9eee-4635d8eab559",
            name: "Lagos Island",
            unitId: "c676c409-1064-4777-89bc-e0ae70d742ba",
          },
          {
            id: "7f0e6273-1436-4805-bce8-4271f9541fb4",
            name: "Lagos Mainland",
            unitId: "c676c409-1064-4777-89bc-e0ae70d742ba",
          },
          {
            id: "70eae81f-1801-4dce-8db1-98728dce21bc",
            name: "Eti-Osa",
            unitId: "c676c409-1064-4777-89bc-e0ae70d742ba",
          },
          {
            id: "c96e5fcd-487a-45cc-965f-1579cbcaaea6",
            name: "Ajeromi-Ifelodun",
            unitId: "c676c409-1064-4777-89bc-e0ae70d742ba",
          },
          {
            id: "b069b797-88ef-4907-a8a9-22e0b29b797e",
            name: "Ifako – Ijaiye Ijokoro",
            unitId: "c676c409-1064-4777-89bc-e0ae70d742ba",
          },
          {
            id: "91046460-cd35-4aeb-870b-d4fd4b196b71",
            name: "Badagry",
            unitId: "c676c409-1064-4777-89bc-e0ae70d742ba",
          },
          {
            id: "123b1ec5-b202-4999-a521-bda82df46a7e",
            name: "Amuwo Odofin",
            unitId: "c676c409-1064-4777-89bc-e0ae70d742ba",
          },
          {
            id: "88257b87-39c2-4e4b-9d2b-f5328a40f6d2",
            name: "Alimosho",
            unitId: "c676c409-1064-4777-89bc-e0ae70d742ba",
          },
          {
            id: "82a61758-a0bc-4c16-99e9-42222af43640",
            name: "Apapa",
            unitId: "c676c409-1064-4777-89bc-e0ae70d742ba",
          },
          {
            id: "4cd4c221-b679-49d0-ad92-3c5aa35a8d66",
            name: "Ikeja",
            unitId: "c676c409-1064-4777-89bc-e0ae70d742ba",
          },
          {
            id: "ca5887c7-1f7e-418c-b382-eb872fa8f5fd",
            name: "Mushin",
            unitId: "c676c409-1064-4777-89bc-e0ae70d742ba",
          },
          {
            id: "bf19c1d6-e182-4a59-a17b-0a5acca82a3c",
            name: "Ikorodu",
            unitId: "c676c409-1064-4777-89bc-e0ae70d742ba",
          },
          {
            id: "f8357bce-b795-447f-adb8-6afe18d01111",
            name: "Oshodi-Isolo",
            unitId: "c676c409-1064-4777-89bc-e0ae70d742ba",
          },
          {
            id: "53039c91-ad13-451b-9c72-e1859dcacac5",
            name: "Ojo",
            unitId: "c676c409-1064-4777-89bc-e0ae70d742ba",
          },
          {
            id: "602ac2cf-b3ba-4e6f-ad21-df5e4c784e81",
            name: "Somolu",
            unitId: "c676c409-1064-4777-89bc-e0ae70d742ba",
          },
          {
            id: "7c637e60-75a3-45d8-904e-f2814c0d9f43",
            name: "Epe",
            unitId: "c676c409-1064-4777-89bc-e0ae70d742ba",
          },
          {
            id: "939984e3-6403-4678-848a-99cfe24403a9",
            name: "Agege",
            unitId: "c676c409-1064-4777-89bc-e0ae70d742ba",
          },
          {
            id: "01e5d358-2792-4d4b-b632-855842bf0587",
            name: "Surulere",
            unitId: "c676c409-1064-4777-89bc-e0ae70d742ba",
          },
          {
            id: "d02ef0f1-7015-49d7-b4ea-b7b77bf04389",
            name: "Kosofe",
            unitId: "c676c409-1064-4777-89bc-e0ae70d742ba",
          },
        ],
        name: "Primary Healthcare Centre",
        subUnits: [],
      },
      {
        id: "85e6f0f3-921f-4b47-adbe-a2cb1711c944",
        location: [
          {
            id: "6840b63a-2ded-46b6-a57a-9444114f7140",
            name: "Lagos Island",
            unitId: "85e6f0f3-921f-4b47-adbe-a2cb1711c944",
          },
          {
            id: "c2486e50-7d8c-4c24-ab3d-d4fab408222a",
            name: "Lagos Mainland",
            unitId: "85e6f0f3-921f-4b47-adbe-a2cb1711c944",
          },
          {
            id: "8d36a52e-b9e0-44a8-8aee-3d8f89eb5aa5",
            name: "Eti-Osa",
            unitId: "85e6f0f3-921f-4b47-adbe-a2cb1711c944",
          },
          {
            id: "3ea26213-7b7e-46e2-b1a6-725821c0e35f",
            name: "Ajeromi",
            unitId: "85e6f0f3-921f-4b47-adbe-a2cb1711c944",
          },
          {
            id: "2dfe4cb5-4ce1-404b-87e7-75d55690b54b",
            name: "Apapa",
            unitId: "85e6f0f3-921f-4b47-adbe-a2cb1711c944",
          },
          {
            id: "89d908b2-cf33-42e4-bdc9-12c919918984",
            name: "Ifako – Ijaiye Ijokoro",
            unitId: "85e6f0f3-921f-4b47-adbe-a2cb1711c944",
          },
          {
            id: "5b03a760-931d-443f-902b-0e3d37a6af9f",
            name: "Badagry",
            unitId: "85e6f0f3-921f-4b47-adbe-a2cb1711c944",
          },
          {
            id: "80031f93-31c6-4c8d-b273-f0542d5cc90e",
            name: "Amuwo Odofin",
            unitId: "85e6f0f3-921f-4b47-adbe-a2cb1711c944",
          },
          {
            id: "8520339a-a443-4ed4-9836-4b0755ad1d79",
            name: "Alimosho",
            unitId: "85e6f0f3-921f-4b47-adbe-a2cb1711c944",
          },
          {
            id: "661d8517-2d3a-46e6-8832-e35db3b4d0e6",
            name: "Ikeja",
            unitId: "85e6f0f3-921f-4b47-adbe-a2cb1711c944",
          },
          {
            id: "ec714642-f771-443c-9332-5fc4e5acb27e",
            name: "Mushin",
            unitId: "85e6f0f3-921f-4b47-adbe-a2cb1711c944",
          },
          {
            id: "40febb11-e1fd-4459-8e07-f77dfdf47be7",
            name: "Ikorodu",
            unitId: "85e6f0f3-921f-4b47-adbe-a2cb1711c944",
          },
          {
            id: "57259390-a450-44d6-b97e-82160c2fa3ac",
            name: "Ojo",
            unitId: "85e6f0f3-921f-4b47-adbe-a2cb1711c944",
          },
          {
            id: "8a1f34e4-7110-4d2b-84d3-d3fb190c2b4e",
            name: "Oshodi-Isolo",
            unitId: "85e6f0f3-921f-4b47-adbe-a2cb1711c944",
          },
          {
            id: "02e257f4-84b1-4dd3-aa41-9c3a86beff1f",
            name: "Somolu",
            unitId: "85e6f0f3-921f-4b47-adbe-a2cb1711c944",
          },
          {
            id: "12db3378-bb11-405e-b6f1-552ee34a23cd",
            name: "Epe",
            unitId: "85e6f0f3-921f-4b47-adbe-a2cb1711c944",
          },
          {
            id: "0406f9da-326a-4967-b9cd-f4989174945d",
            name: "Agege",
            unitId: "85e6f0f3-921f-4b47-adbe-a2cb1711c944",
          },
          {
            id: "c4f75fac-e908-4edf-b01b-926310252d59",
            name: "Surulere",
            unitId: "85e6f0f3-921f-4b47-adbe-a2cb1711c944",
          },
          {
            id: "51de0de4-c5b3-44ca-880c-5f941615d34b",
            name: "Kosofe",
            unitId: "85e6f0f3-921f-4b47-adbe-a2cb1711c944",
          },
        ],
        name: "General Hospital",
        subUnits: [],
      },
      {
        id: "9dd5f863-35f3-4722-a4e7-2cc3ba1a9229",
        location: [
          {
            id: "68014341-f7af-4f5c-a9fc-f24dd9cf5d12",
            name: "Mirabel",
            unitId: "9dd5f863-35f3-4722-a4e7-2cc3ba1a9229",
          },
          {
            id: "6f5b49dd-5cf2-4d97-8a7f-aebfcc44c16a",
            name: "Warrif",
            unitId: "9dd5f863-35f3-4722-a4e7-2cc3ba1a9229",
          },
          {
            id: "7953cc9b-6e16-4f19-8aa4-7e3a5c32be4a",
            name: "Idera",
            unitId: "9dd5f863-35f3-4722-a4e7-2cc3ba1a9229",
          },
          {
            id: "e0b9ec55-7702-4af4-98d1-2ad90de2c767",
            name: "Others",
            unitId: "9dd5f863-35f3-4722-a4e7-2cc3ba1a9229",
          },
        ],
        name: "NGOs",
        subUnits: [],
      },
    ],
  },
  {
    id: "0f1a8cf6-47e0-4e57-a9be-a2de1a4e23dd",
    location: [
      {
        id: "2ddf614c-af10-4fc4-a8c3-1ce2cfbe2c48",
        name: "Lagos Island",
        unitId: "0f1a8cf6-47e0-4e57-a9be-a2de1a4e23dd",
      },
      {
        id: "ddc69cde-9d97-43c8-95eb-3614a8b1a2d7",
        name: "Lagos Mainland",
        unitId: "0f1a8cf6-47e0-4e57-a9be-a2de1a4e23dd",
      },
      {
        id: "b7a6be1a-0026-4160-828c-8ace628f5f1f",
        name: "Eti-Osa",
        unitId: "0f1a8cf6-47e0-4e57-a9be-a2de1a4e23dd",
      },
      {
        id: "629508cb-335b-4d32-a611-2ae5389aa29a",
        name: "Ajeromi-Ifelodun",
        unitId: "0f1a8cf6-47e0-4e57-a9be-a2de1a4e23dd",
      },
      {
        id: "a0830989-5f61-43de-a311-42a1e13860f9",
        name: "Ifako – Ijaiye Ijokoro",
        unitId: "0f1a8cf6-47e0-4e57-a9be-a2de1a4e23dd",
      },
      {
        id: "984a0784-2b39-49c5-8d49-08cacabf5128",
        name: "Badagry",
        unitId: "0f1a8cf6-47e0-4e57-a9be-a2de1a4e23dd",
      },
      {
        id: "7cb86845-ed5f-4c80-a39d-a263e4c2e96c",
        name: "Amuwo Odofin",
        unitId: "0f1a8cf6-47e0-4e57-a9be-a2de1a4e23dd",
      },
      {
        id: "52c1efbe-a2e7-4fa0-a622-42a8dad62912",
        name: "Alimosho",
        unitId: "0f1a8cf6-47e0-4e57-a9be-a2de1a4e23dd",
      },
      {
        id: "45d4606d-d0ce-42d3-aa66-aeed4862bc29",
        name: "Apapa",
        unitId: "0f1a8cf6-47e0-4e57-a9be-a2de1a4e23dd",
      },
      {
        id: "14276d78-e08c-4155-a1aa-c19fbbe54290",
        name: "Ikeja",
        unitId: "0f1a8cf6-47e0-4e57-a9be-a2de1a4e23dd",
      },
      {
        id: "9c0d38c4-4d38-48d4-9c1f-1c9d58fa8440",
        name: "Mushin",
        unitId: "0f1a8cf6-47e0-4e57-a9be-a2de1a4e23dd",
      },
      {
        id: "09333001-a38b-4887-bfe5-d41a031ec613",
        name: "Ikorodu",
        unitId: "0f1a8cf6-47e0-4e57-a9be-a2de1a4e23dd",
      },
      {
        id: "faa537c7-f53a-473e-aa39-a60be5b94d23",
        name: "Ojo",
        unitId: "0f1a8cf6-47e0-4e57-a9be-a2de1a4e23dd",
      },
      {
        id: "36d2afb8-2254-4492-b7ad-e4537075fc8c",
        name: "Oshodi-Isolo",
        unitId: "0f1a8cf6-47e0-4e57-a9be-a2de1a4e23dd",
      },
      {
        id: "b88387ab-cc72-40dc-bd7e-39b783a4d8b5",
        name: "Somolu",
        unitId: "0f1a8cf6-47e0-4e57-a9be-a2de1a4e23dd",
      },
      {
        id: "2970da76-5678-44a5-85bc-16299eeaacd7",
        name: "Epe",
        unitId: "0f1a8cf6-47e0-4e57-a9be-a2de1a4e23dd",
      },
      {
        id: "64354203-9956-4da6-9257-c9345fede9af",
        name: "Agege",
        unitId: "0f1a8cf6-47e0-4e57-a9be-a2de1a4e23dd",
      },
      {
        id: "329be2b4-dc56-4bf2-8110-c9cc0af71d18",
        name: "Surulere",
        unitId: "0f1a8cf6-47e0-4e57-a9be-a2de1a4e23dd",
      },
      {
        id: "4e8e66cf-0f9e-4705-a95b-c3476b839f55",
        name: "Kosofe",
        unitId: "0f1a8cf6-47e0-4e57-a9be-a2de1a4e23dd",
      },
      {
        id: "c121bcb3-6d0f-4df8-a69a-ef701d443481",
        name: "DSVA FSS",
        unitId: "0f1a8cf6-47e0-4e57-a9be-a2de1a4e23dd",
      },
    ],
    name: "FAMILY SOCIAL SERVICE",
    subUnits: [],
  },
  {
    id: "b5aed4f3-838f-498a-b629-1b13efae3f28",
    location: [],
    name: "Authentic/Alternative Dispute Resolution (DSVA-ADR)",
    subUnits: [],
  },
  {
    id: "cc650f35-c655-405b-b80d-d5d31575d317",
    location: [],
    name: "Psychosocial Support and Counseling (DSVA-PSS)",
    subUnits: [],
  },
  {
    id: "d7a1c40f-f918-42ba-9a4d-9422cb8b7180",
    location: [],
    name: "Lagos State Child Protection Unit",
    subUnits: [],
  },
  {
    id: "72d08f2c-7176-4838-97ca-d2ce96761f6d",
    location: [],
    name: "Shelter",
    subUnits: [],
  },
  {
    id: "ace90d7c-075b-422d-9f48-f0f1a5c3a749",
    location: [],
    name: "Emergency/RRS",
    subUnits: [],
  },
  {
    id: "c3017814-6738-4d84-a1ff-ebafc5da376d",
    location: [],
    name: "Citizen Mediation Centre",
    subUnits: [],
  },
  {
    id: "5f1c2d72-32b8-4bbc-be51-e199cdc28153",
    location: [],
    name: "FIDA",
    subUnits: [],
  },
  {
    id: "e5e40e04-1b12-406e-b702-f655a2beee94",
    location: [],
    name: "Outside Lagos",
    subUnits: [],
  },
  {
    id: "470a20f7-2131-4f61-81c0-03e119c0e447",
    location: [
      {
        id: "25d36ac5-0c97-423d-bd13-ecf18ef8a44c",
        name: "Adeniji Adele",
        unitId: "470a20f7-2131-4f61-81c0-03e119c0e447",
      },
      {
        id: "1f758cf8-ec3f-4d42-9fab-fac968dd5de4",
        name: "Ajah",
        unitId: "470a20f7-2131-4f61-81c0-03e119c0e447",
      },
      {
        id: "23fc7513-7c88-42a7-8882-c31252bf23f9",
        name: "Ajegunle",
        unitId: "470a20f7-2131-4f61-81c0-03e119c0e447",
      },
      {
        id: "a7b4c3ab-480e-4096-8005-5165a328a1e9",
        name: "Alakuko",
        unitId: "470a20f7-2131-4f61-81c0-03e119c0e447",
      },
      {
        id: "1909196a-aca7-43a4-80ce-a686e158472d",
        name: "Badagry",
        unitId: "470a20f7-2131-4f61-81c0-03e119c0e447",
      },
      {
        id: "a4df65d8-0527-45cd-b289-d056e6c25ba0",
        name: "Festac",
        unitId: "470a20f7-2131-4f61-81c0-03e119c0e447",
      },
      {
        id: "a27e6769-b5a1-4f5b-bbbe-44a7d2051290",
        name: "Ikotun",
        unitId: "470a20f7-2131-4f61-81c0-03e119c0e447",
      },
      {
        id: "d095b1f0-a041-4c6f-9d62-8339e8404bf8",
        name: "Igando",
        unitId: "470a20f7-2131-4f61-81c0-03e119c0e447",
      },
      {
        id: "b0dc3c44-ff2b-4254-9c98-95bbcc3ea628",
        name: "Ikeja",
        unitId: "470a20f7-2131-4f61-81c0-03e119c0e447",
      },
      {
        id: "d8e9d6b9-e8d9-4201-8178-4a10a2f56baf",
        name: "Ilupeju",
        unitId: "470a20f7-2131-4f61-81c0-03e119c0e447",
      },
      {
        id: "9e5ed04c-6537-46cf-a82a-495620b8c979",
        name: "Ipaja",
        unitId: "470a20f7-2131-4f61-81c0-03e119c0e447",
      },
      {
        id: "16872067-b4f0-4990-ac09-6bdfa67006f1",
        name: "Ikorodu – Igbogbo",
        unitId: "470a20f7-2131-4f61-81c0-03e119c0e447",
      },
      {
        id: "a5dfe658-f85c-4814-95c8-fadda0929405",
        name: "Ikorodu – Imota",
        unitId: "470a20f7-2131-4f61-81c0-03e119c0e447",
      },
      {
        id: "15e3d4ba-1e79-4b9d-a762-792ac198b923",
        name: "Ikorodu – Owutu",
        unitId: "470a20f7-2131-4f61-81c0-03e119c0e447",
      },
      {
        id: "81807117-7920-473d-b30c-41cc1fa8771c",
        name: "Okokomaiko",
        unitId: "470a20f7-2131-4f61-81c0-03e119c0e447",
      },
      {
        id: "e7f5c913-1b8c-498d-8102-593a1af66a55",
        name: "Bariga",
        unitId: "470a20f7-2131-4f61-81c0-03e119c0e447",
      },
      {
        id: "953da9c2-e197-49af-a2d5-5d583ff1c40e",
        name: "Epe",
        unitId: "470a20f7-2131-4f61-81c0-03e119c0e447",
      },
      {
        id: "a7372495-1411-4c5f-9b13-f08df2323f18",
        name: "Isokoko",
        unitId: "470a20f7-2131-4f61-81c0-03e119c0e447",
      },
      {
        id: "7bfca914-3e0e-49e0-bb62-99cde2697f98",
        name: "Surulere",
        unitId: "470a20f7-2131-4f61-81c0-03e119c0e447",
      },
      {
        id: "a1de5ac3-ef76-406d-a484-37b2d83cae23",
        name: "Ilasan-Lekki",
        unitId: "470a20f7-2131-4f61-81c0-03e119c0e447",
      },
      {
        id: "4e970908-ebf3-48a3-9c71-027bb121e287",
        name: "Ketu",
        unitId: "470a20f7-2131-4f61-81c0-03e119c0e447",
      },
      {
        id: "015fa463-35d9-45a7-a8e2-fe56ab4870eb",
        name: "Gender",
        unitId: "470a20f7-2131-4f61-81c0-03e119c0e447",
      },
      {
        id: "e8510f57-8e3d-4752-a309-89436b735886",
        name: "DSVA PID",
        unitId: "470a20f7-2131-4f61-81c0-03e119c0e447",
      },
      {
        id: "9d783872-f509-4817-a3fe-b708886d3d66",
        name: "Police Station (Non-GBV)",
        unitId: "470a20f7-2131-4f61-81c0-03e119c0e447",
      },
      {
        id: "d85dcf8a-b809-41e2-adf5-1b5c0cb6bf6a",
        name: "Outside Lagos",
        unitId: "470a20f7-2131-4f61-81c0-03e119c0e447",
      },
      {
        id: "2fcc9ee2-b635-49ee-b02d-575812962fae",
        name: "Nepa",
        unitId: "470a20f7-2131-4f61-81c0-03e119c0e447",
      },
    ],
    name: "FAMILY SUPPORT UNIT",
    subUnits: [],
  },
];

export const demoLocalGovermant = [
  {
    id: "2ddf614c-af10-4fc4-a8c3-1ce2cfbe2c48",
    name: "Lagos Island",
    unitId: "0f1a8cf6-47e0-4e57-a9be-a2de1a4e23dd",
  },
  {
    id: "ddc69cde-9d97-43c8-95eb-3614a8b1a2d7",
    name: "Lagos Mainland",
    unitId: "0f1a8cf6-47e0-4e57-a9be-a2de1a4e23dd",
  },
  {
    id: "b7a6be1a-0026-4160-828c-8ace628f5f1f",
    name: "Eti-Osa",
    unitId: "0f1a8cf6-47e0-4e57-a9be-a2de1a4e23dd",
  },
  {
    id: "629508cb-335b-4d32-a611-2ae5389aa29a",
    name: "Ajeromi-Ifelodun",
    unitId: "0f1a8cf6-47e0-4e57-a9be-a2de1a4e23dd",
  },
  {
    id: "a0830989-5f61-43de-a311-42a1e13860f9",
    name: "Ifako – Ijaiye Ijokoro",
    unitId: "0f1a8cf6-47e0-4e57-a9be-a2de1a4e23dd",
  },
  {
    id: "984a0784-2b39-49c5-8d49-08cacabf5128",
    name: "Badagry",
    unitId: "0f1a8cf6-47e0-4e57-a9be-a2de1a4e23dd",
  },
  {
    id: "7cb86845-ed5f-4c80-a39d-a263e4c2e96c",
    name: "Amuwo Odofin",
    unitId: "0f1a8cf6-47e0-4e57-a9be-a2de1a4e23dd",
  },
  {
    id: "52c1efbe-a2e7-4fa0-a622-42a8dad62912",
    name: "Alimosho",
    unitId: "0f1a8cf6-47e0-4e57-a9be-a2de1a4e23dd",
  },
  {
    id: "45d4606d-d0ce-42d3-aa66-aeed4862bc29",
    name: "Apapa",
    unitId: "0f1a8cf6-47e0-4e57-a9be-a2de1a4e23dd",
  },
  {
    id: "14276d78-e08c-4155-a1aa-c19fbbe54290",
    name: "Ikeja",
    unitId: "0f1a8cf6-47e0-4e57-a9be-a2de1a4e23dd",
  },
  {
    id: "9c0d38c4-4d38-48d4-9c1f-1c9d58fa8440",
    name: "Mushin",
    unitId: "0f1a8cf6-47e0-4e57-a9be-a2de1a4e23dd",
  },
  {
    id: "09333001-a38b-4887-bfe5-d41a031ec613",
    name: "Ikorodu",
    unitId: "0f1a8cf6-47e0-4e57-a9be-a2de1a4e23dd",
  },
  {
    id: "faa537c7-f53a-473e-aa39-a60be5b94d23",
    name: "Ojo",
    unitId: "0f1a8cf6-47e0-4e57-a9be-a2de1a4e23dd",
  },
  {
    id: "36d2afb8-2254-4492-b7ad-e4537075fc8c",
    name: "Oshodi-Isolo",
    unitId: "0f1a8cf6-47e0-4e57-a9be-a2de1a4e23dd",
  },
  {
    id: "b88387ab-cc72-40dc-bd7e-39b783a4d8b5",
    name: "Somolu",
    unitId: "0f1a8cf6-47e0-4e57-a9be-a2de1a4e23dd",
  },
  {
    id: "2970da76-5678-44a5-85bc-16299eeaacd7",
    name: "Epe",
    unitId: "0f1a8cf6-47e0-4e57-a9be-a2de1a4e23dd",
  },
  {
    id: "64354203-9956-4da6-9257-c9345fede9af",
    name: "Agege",
    unitId: "0f1a8cf6-47e0-4e57-a9be-a2de1a4e23dd",
  },
  {
    id: "329be2b4-dc56-4bf2-8110-c9cc0af71d18",
    name: "Surulere",
    unitId: "0f1a8cf6-47e0-4e57-a9be-a2de1a4e23dd",
  },
  {
    id: "4e8e66cf-0f9e-4705-a95b-c3476b839f55",
    name: "Kosofe",
    unitId: "0f1a8cf6-47e0-4e57-a9be-a2de1a4e23dd",
  },
  {
    id: "c121bcb3-6d0f-4df8-a69a-ef701d443481",
    name: "DSVA FSS",
    unitId: "0f1a8cf6-47e0-4e57-a9be-a2de1a4e23dd",
  },
];

export const demoCaseManager = [
  {
    id: "1",
    name: "Bolaji Lawal",
  },
  {
    id: "1",
    name: "Chura Vasquez",
  },
  {
    id: "1",
    name: "Nonso Marcus",
  },
];

export const followupDemoData = [
  {
    referralUnit: "1",
    Location: "Bolaji Lawal",
  },
  {
    id: "2",
    name: "Chura Vasquez",
  },
  {
    id: "3",
    name: "Nonso Marcus",
  },
];
