<template>
    <div>
        <card v-if="page === 1" class="card" title="Add Referral Location"
            subTitle="Select a referral unit to add a location">
            <form>
                <div class="row">
                    <div class="col-md-12">
                        <fg-input-dropdown type="text" label="Referral Unit" placeholder="e.g Medical" required
                            :error="error.referralUnitId" :options="referralUnitOption"
                            v-model="referralUnitId"></fg-input-dropdown>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <fg-input type="text" label="Location" placeholder="e.g Lagos Island" required
                            :error="error.location" v-model="location"></fg-input>
                    </div>
                </div>

                <p-button type="success" style="margin-left: auto; display: block" round
                    @click.native.prevent="handleAddLocation">
                    Add Location
                </p-button>
            </form>
        </card>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import swal from "sweetalert";

export default {
    data() {
        return {
            page: 1,
            error: {
                referralUnitId: null,
                location: null,
            },
            referralUnitId: "",
            referralUnitOption: [],
            location: "",
        };
    },
    methods: {
        ...mapActions("cases", ["fetchAllReferralUnit", "addReferralLocation"]),
        flattenUnits(units) {
            let flattened = [];

            units.forEach((unit) => {
                // Add the current unit to the flattened array
                flattened.push({
                    key: unit.id,
                    value: unit.id,
                    text: unit.name,
                });

                // If the unit has subunits, recursively flatten
                if (unit.subUnits && unit.subUnits.length > 0) {
                    flattened = flattened.concat(this.flattenUnits(unit.subUnits));
                }
            });

            return flattened;
        },
        handleAddLocation() {
            if (!this.referralUnitId) {
                this.error.referralUnitId = "Referral Unit is required";
                return;
            }
            if (!this.location) {
                this.error.location = "Location is required";
                return;
            }

            this.error.referralUnitId = null;
            this.error.location = null;

            this.submitForm();
        },
        resetForm() {
            this.page = 1;
            this.referralUnitId = "";
            this.location = "";
            this.error = {
                referralUnitId: null,
                location: null,
            };
        },
        validateForm() {
            let isValid = true;
            if (!this.referralUnitId) {
                this.error.referralUnitId = "Referral Unit is required";
                isValid = false;
            }
            if (!this.location) {
                this.error.location = "Location is required";
                isValid = false;
            }
            return isValid;
        },
        async submitForm() {
            if (this.validateForm()) {
                try {
                    const { referralUnitId, location } = this;
                    swal({
                        title: "Complete Submission",
                        text: "Are You sure you want to add this location?",
                        buttons: {
                            cancel: true,
                            confirm: true,
                        },
                    }).then((results) => {
                        if (results) {
                            this.addReferralLocation({ referralUnitId: this.referralUnitId, name: this.location })
                                .then(() => {
                                    this.$notify({
                                        message: "Location Added Successfully",
                                        icon: "ti-check-box",
                                        horizontalAlign: "center",
                                        verticalAlign: "top",
                                        type: "success",
                                    });
                                    this.resetForm();
                                })
                                .catch((error) => {
                                    this.$notify({
                                        message: "Failed to add location",
                                        icon: "ti-alert",
                                        horizontalAlign: "center",
                                        verticalAlign: "top",
                                        type: "danger",
                                    });
                                });
                        }
                    });
                } catch (error) {
                    this.$notify({
                        message: "Failed to add location",
                        icon: "ti-alert",
                        horizontalAlign: "center",
                        verticalAlign: "top",
                        type: "danger",
                    });
                }
            }
        },
    },
    computed: {
        ...mapState("cases", ["allReferralUnits"]),
    },
    mounted() {
        this.fetchAllReferralUnit()
            .then(() => {
                this.units = this.allReferralUnits.units;
                this.referralUnitOption = this.flattenUnits(this.units); // Call flattenUnits and assign the result
            })
            .catch((error) => {
                console.error("Failed to fetch referral units:", error);
            });
    },
};
</script>

<style scoped>
/* Add your styles here */
.full {
    width: 100%;
}
</style>