<template>
  <div class="col-6">
    <card
      class="card"
      title="Overview"
      subTitle="Add Additional Information about the case"
    >
      <div class="row">
        <div class="col-md-12">
          <label>Date Of Occurrence</label>
          <date-picker
            name="date"
            v-model="generalInfo.dateOfOccurrence"
            :config="config"
          ></date-picker>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <fg-input-dropdown
            type="text"
            label="LGA Of Occurrence"
            placeholder="Nature of Violence"
            :error="error.lgaOfOccurrence"
            required
            :options="lgaOfOccurrenceOptions"
            v-model="generalInfo.lgaOfOccurrence"
          >
          </fg-input-dropdown>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <fg-input-dropdown
            type="text"
            label="Case Manager"
            placeholder="Case Manager"
            :error="error.caseManagerId"
            required
            :options="caseManagerOptions"
            v-model="caseManager"
          >
          </fg-input-dropdown>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <fg-input-dropdown
            type="text"
            label="Referred By"
            :error="error.referredBy"
            :options="referredByOptions"
            v-model="generalInfo.referredBy"
          >
          </fg-input-dropdown>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <fg-input-dropdown
            type="text"
            label="Nature"
            placeholder="Nature of Violence"
            :error="error.nature"
            required
            :options="natureOptions"
            v-model="generalInfo.nature"
          >
          </fg-input-dropdown>
        </div>
      </div>
    </card>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { getCounsellor } from "@/utils/auth";
import {
  demoCaseManager,
  demoLocalGovermant,
} from "../../components/ReferralPathways.vue/pathwayDemoData";

export default {
  data() {
    return {
      caseManager: null,
      config: {
        format: "",
        useCurrent: false,
        showClear: true,
        showClose: true,
      },
      generalInfo: {
        workspaceId: null,
        callHandlerName: "",
        callHandlerId: null,
        dateOfOccurrence: null,
        lgaOfOccurrence: "",
        nature: "",
        referredBy: "",
        status: "Ongoing",
        stateIso2: "NG-LA",
        caseManagerId: null,
        notes: "",
      },
      referredByOptions: [
        { key: 0, value: "Social Media", text: "Social Media" },
        { key: 1, value: "Neighbour", text: "Neighbour" },
        { key: 2, value: "Friend", text: "Friend" },
        { key: 3, value: "TV Adverts", text: "TV Adverts" },
        { key: 4, value: "Billboard Poster", text: "Billboard Poster" },
        { key: 5, value: "Community Awareness", text: "Community Awareness" },
        { key: 6, value: "Others", text: "Others" },
      ],
      natureOptions: [
        { key: 0, value: "Rape", text: "Rape" },
        { key: 1, value: "Sexual Assault", text: "Sexual Assault" },
        { key: 3, value: "Sexual Harassment", text: "Sexual Harassment" },
        { key: 4, value: "Physical Assault", text: "Physical Assault" },
        { key: 5, value: "Denial of Resources", text: "Denial of Resource" },
        { key: 6, value: "Child Abuse", text: "Child Abuse" },
        { key: 7, value: "Defilement", text: "Defilement" },
        { key: 8, value: "Stalking", text: "Stalking" },
        {
          key: 9,
          value: "Revenge Porn (Releasing of Nudes)",
          text: "Revenge Porn (Releasing of Nudes)",
        },
        { key: 10, value: "Custody & Access", text: "Custody & Access" },
        { key: 11, value: "Verbal Abuse", text: "Verbal Abuse" },
        { key: 12, value: "Emotional Abuse", text: "Emotional Abuse" },
        { key: 13, value: "Non GBV", text: "Non GBV" },
        { key: 14, value: "Enquiry", text: "Enquiry" },
      ],
      stateIso2Options: [
        { key: 0, value: "NG-FC", text: "NG-FC" },
        { key: 0, value: "NG-LA", text: "NG-LA" },
        { key: 0, value: "NG-AD", text: "NG-AD" },
      ],
    };
  },
  props: {
    updateForm: {
      type: Function,
      required: true,
    },
    formTab: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: false,
    },
    error: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState("state", ["stateLga"]),
    ...mapState("auth", ["counsellorManagers"]),
    lgaOfOccurrenceOptions() {
      const demoData = demoLocalGovermant.map((lg) => ({
        key: lg.id,
        value: lg.name,
        text: lg.name,
      }));
      const localgoverment = this.stateLga.map((v, idx) => {
        return {
          key: idx,
          value: v,
          text: v,
        };
      });
      return !window.location.href.includes("localhost")
        ? localgoverment
        : demoData;
    },
    caseManagerOptions() {
      const demoData = demoCaseManager.map((cm) => ({
        key: cm.id,
        value: cm.id,
        text: cm.name,
      }));
      const caseManager = this.counsellorManagers.map((v, idx) => {
        return {
          key: idx,
          value: v.id.toString(),
          text: `${v.name} - ${v.role}`,
        };
      });

      return !window.location.href.includes("localhost")
        ? caseManager
        : demoData;
    },
  },
  watch: {
    caseManager(newValue) {
      const manager = this.counsellorManagers.find(
        (m) => m.id.toString() === newValue
      );
      this.generalInfo.caseManagerId = parseInt(newValue, 10);
    },
    generalInfo: {
      deep: true,
      handler(newValue) {
        this.updateForm(4, newValue);
      },
    },
  },
  async mounted() {
    const counsellor = getCounsellor();
    if (!counsellor) {
      console.error("Counsellor object is null or undefined");
      return;
    }

    this.generalInfo = {
      ...this.generalInfo,
      ...this.data,
      dateOfOccurrence: new Date(this.data.dateOfOccurrence || new Date()),
      workspaceId: counsellor ? counsellor.workspaceId : 1,
      stateIso2: counsellor.stateIso ? counsellor.stateIso : "NG/LA",
      callHandlerId: counsellor ? counsellor.counsellorId : 1,
      callHandlerName: counsellor ? counsellor.name : "Adisa",
    };
    this.caseManager = counsellor.counsellorId.toString();

    await this.fetchStateLga({ stateIso2: counsellor.stateIso });
    await this.fetchCounsellorManagers({ workspaceId: counsellor.workspaceId });
  },
  methods: {
    ...mapActions("state", ["fetchStateLga"]),
    ...mapActions("auth", ["fetchCounsellorManagers"]),
  },
};
</script>
<style></style>
