<template>
  <div class="col-6" v-if="units.length > 0">
    <card
      class="card"
      title="Referral Pathways"
      subTitle="Add Information about the referrals unit"
    >
      <form @submit.prevent="handleSubmit">
        <!-- Loop through units -->
        <div v-if="pathwayError" class="alert alert-danger">
          {{ pathwayError || "Please select at least one referral pathway" }}
        </div>
        <div v-for="unit in units" :key="unit.name" class="form-group">
          <!-- Unit Checkbox -->
          <label>
            <input
              type="checkbox"
              v-model="selectedUnits"
              :value="unit.name"
              @change="handleUnitSelection(unit)"
            />
            {{ unit.name }}
          </label>

          <!-- Sub-Unit Selection (For any unit with subUnits) -->
          <div
            v-if="unit.subUnits.length > 0 && selectedUnits.includes(unit.name)"
          >
            <label>Select Sub-Unit:</label>
            <select
              v-model="selectedSubUnits[unit.name]"
              class="input-group form-control"
            >
              <option
                v-for="subUnit in unit.subUnits"
                :key="subUnit.name"
                :value="subUnit.id"
              >
                {{ subUnit.name }}
              </option>
            </select>

            <!-- Location Selection for Sub-Unit -->
            <div v-if="selectedSubUnits[unit.name]">
              <label>Select Location:</label>
              <select
                v-model="selectedLocations[unit.name]"
                class="input-group form-control"
              >
                <option
                  v-for="loc in getSubUnitLocations(
                    unit.name,
                    selectedSubUnits[unit.name]
                  )"
                  :key="loc.id"
                  :value="loc.id"
                >
                  {{ loc.name }}
                </option>
              </select>
            </div>
          </div>

          <!-- Location Selection for Units without Sub-Units -->
          <div
            v-else-if="
              selectedUnits.includes(unit.name) && unit.location.length > 0
            "
          >
            <label>Select Location:</label>
            <select
              v-model="selectedLocations[unit.name]"
              class="input-group form-control"
            >
              <option
                v-for="loc in unit.location"
                :key="loc.id"
                :value="loc.id"
              >
                {{ loc.name }}
              </option>
            </select>
          </div>
        </div>
      </form>
    </card>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  props: {
    updateForm: {
      type: Function,
      required: true,
    },
    formTab: {
      type: String,
      required: true,
    },
    error: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      pathwayError: null,
      units: [],
      selectedUnits: [],
      selectedSubUnits: {},
      selectedLocations: {},
    };
  },
  watch: {
    selectedUnits: {
      handler: "updateFormData",
      deep: true,
    },
    selectedSubUnits: {
      handler: "updateFormData",
      deep: true,
    },
    selectedLocations: {
      handler: "updateFormData",
      deep: true,
    },
    error: {
      handler(newError) {
        this.pathwayError = newError.referralPathway;
      },
      deep: true, // Watch nested properties
    },
  },
  methods: {
    ...mapActions("cases", ["fetchAllReferralUnit"]),
    getSubUnitLocations(unitName, subUnitId) {
      const unit = this.units.find((u) => u.name === unitName);
      if (unit) {
        const subUnit = unit.subUnits.find((sub) => sub.id === subUnitId);
        return subUnit ? subUnit.location : [];
      }
      return [];
    },
    handleUnitSelection(unit) {
      if (!this.selectedUnits.includes(unit.name)) {
        // Reset sub-unit and location if unit is deselected
        this.$set(this.selectedSubUnits, unit.name, null);
        this.$set(this.selectedLocations, unit.name, null);
      }
      this.pathwayError = null;
    },
    updateFormData() {
      const finalData = this.selectedUnits.map((unitName) => {
        const unit = this.units.find((u) => u.name === unitName);

        let dataEntry = {
          referralUnitId: unit.id,
          referralLocationId: this.selectedLocations[unit.name] || null,
        };

        // Add sub-unit if the unit has sub-units
        if (unit.subUnits.length > 0 && this.selectedSubUnits[unit.name]) {
          dataEntry.referralUnitId = this.selectedSubUnits[unit.name];
          dataEntry.referralLocationId =
            this.selectedLocations[unit.name] || null;
        }

        return dataEntry;
      });
      this.updateForm(5, finalData);
    },
  },
  computed: {
    ...mapState("cases", ["allReferralUnits"]),
  },
  mounted() {
    this.fetchAllReferralUnit()
      .then(() => {
        this.units = this.allReferralUnits.units;
      })
      .catch((error) => {
        console.error("Failed to fetch referral units:", error);
      });
  },
};
</script>

<style scoped>
/* Add your styles here */
</style>
