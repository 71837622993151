<template>
    <div class="col-12">
        <chart-card title="Referral Pathways Stacked Bar Chart" sub-title="Group Case by different categories"
            v-if="!isFetchingReferralPathwaysBarChart" :chart-data="data" :chart-options="options" chart-type="Bar"
            footer-text='Last Two weeks (Default)'>
            <div class="row" slot="filter">
                <div class="col-12">
                    <div class="dropdown menu">
                        <button class="btn btn-round btn-info dropdown-toggle" type="button" id="dropdownMenuButton"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Filter
                        </button>
                        <div class="dropdown-menu dropdown-menu-left p4">
                            <form class="col-12" @submit.prevent>
                                <fg-input-dropdown type="text" label="Group By" placeholder="e.g Nature" required
                                    :options="tagOptions" v-model="tag">
                                </fg-input-dropdown>
                                <div class="form-group">
                                    <label>From</label>
                                    <date-picker name="startDate" v-model="startDate" :config="config"></date-picker>
                                </div>
                                <div class="form-group">
                                    <label>To</label>
                                    <date-picker name="endDate" v-model="endDate" :config="config"></date-picker>
                                </div>
                                <p-button type="success" style="margin-left: auto; display: block;" round
                                    @click.native.prevent="fetchWithFilter">
                                    Submit
                                </p-button>
                                <br />
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="!data || data.series.length === 0" slot="empty">No Data Available</div>
        </chart-card>
    </div>
</template>
<script>
import {
    mapState,
    mapActions,
} from 'vuex';
import moment from 'moment';
import Chartist from 'chartist';
import ChartistTooltip from 'chartist-plugin-tooltips-updated';
require('chartist-plugin-legend');
import { ChartCard } from "@/components/index";
import { getCounsellor } from '@/utils/auth'
import { oneDayMillis, oneMonthMillis, twoWeeksMillis } from "@/utils/utils";
export default {
    components: {
        ChartCard
    },
    /**
     * Chart data used to render stats, charts. Should be replaced with server data
     */
    data() {
        return {
            referralUnits: null,
            counsellor: null,
            startDate: null,
            endDate: null,
            tag: 'referralUnitId',
            tagOptions: [
                { key: 0, value: "referralUnitId", text: "Referral Unit" },
                { key: 1, value: "referralLocationId", text: "Referral Location" },
                { key: 2, value: "referralUnitType", text: "Referral Unit Type" },
            ],
            config: {
                format: '',
                useCurrent: false,
                showClear: true,
                showClose: true,
            },
            legendNames: [],
            filter: {
                metrics: [
                    {
                        name: "TotalReferralPathways",
                        tags: {
                            workspaceId: []
                        },
                        group_by: [
                            {
                                name: "tag",
                                tags: [
                                    "referralUnitId"
                                ]
                            }
                        ],
                        aggregators: [
                            {
                                name: "sum",
                                sampling: {
                                    value: "1",
                                    unit: "hours"
                                },
                                align_end_time: true
                            }
                        ]
                    }
                ],
                plugins: [],
                cache_time: 0,
                start_absolute: (new Date().getTime() - twoWeeksMillis),
                end_absolute: (new Date().getTime()),
            },
            data: null,
            options: null,
        };
    },
    computed: {
        ...mapState('analytics', ['referralPathwaysBarChart', 'isFetchingReferralPathwaysBarChart']),
        ...mapState("auth", ["counsellorList", "isFetchingCounsellorList"]),
        ...mapState("cases", ["allReferralUnits"]),
    },
    watch: {
        async referralPathwaysBarChart(newValue) {
            await this.formatGraphData(newValue);
        },
        startDate(newValue) {
            this.filter.start_absolute = new Date(newValue).getTime()
        },
        endDate(newValue) {
            this.filter.end_absolute = new Date(newValue).getTime()
        },
        tag(newValue) {
            this.filter.metrics.map(metric => {
                metric.group_by[0].tags = [newValue]
                return metric
            })
        }
    },
    async mounted() {
        this.fetchAllReferralUnit()
            .then(() => {
                this.referralUnits = this.allReferralUnits.units;
            })
            .catch((error) => {
                console.error("Failed to fetch referral units:", error);
            });
        await this.fetchWithFilter();
    },
    async created() {
        this.counsellor = getCounsellor();
    },
    methods: {
        ...mapActions("cases", ["fetchAllReferralUnit"]),
        ...mapActions('analytics', [
            'fetchReferralPathwaysBarChart',
        ]),
        async fetchWithFilter() {
            if ((parseInt(this.filter.end_absolute, 10) - parseInt(this.filter.start_absolute, 10)) <= oneDayMillis) {
                this.filter.metrics.map(metric => {
                    metric.aggregators[0].sampling = {
                        value: '1',
                        unit: 'hours',
                    };
                    metric.group_by[0].tags = [
                        this.tag
                    ];
                    metric.tags.workspaceId = [this.counsellor.workspaceId]
                    return metric
                })
            }
            else if ((parseInt(this.filter.end_absolute, 10) - parseInt(this.filter.start_absolute, 10)) >= oneMonthMillis) {
                this.filter.metrics.map(metric => {
                    metric.aggregators[0].sampling = {
                        value: '1',
                        unit: 'months',
                    };
                    metric.tags.workspaceId = [this.counsellor.workspaceId]
                    return metric
                })
            }
            else {
                this.filter.metrics.map(metric => {
                    metric.aggregators[0].sampling = {
                        value: '1',
                        unit: 'days',
                    };
                    metric.group_by[0].tags = [
                        this.tag
                    ];
                    metric.tags.workspaceId = [this.counsellor.workspaceId]
                    return metric
                })
            }
            const params = this.filter;
            Object.keys(params).forEach((key) => (params[key] === null || params[key] === '') && delete params[key]);
            await this.fetchReferralPathwaysBarChart(params);
        },
        getExpectedTimeStamps(start, end) {
            const expectedTimeStamps = [];
            const startDate = moment(start);
            const endDate = moment(end);
            if ((this.filter.end_absolute - this.filter.start_absolute) <= oneDayMillis) {
                while (startDate.isBefore(endDate)) {
                    expectedTimeStamps.push(startDate.format('h:mm a'));
                    startDate.add(1, 'hours');
                }
                expectedTimeStamps.push(endDate.format('h:mm a'));
            }
            else if ((this.filter.end_absolute - this.filter.start_absolute) >= oneMonthMillis) {
                while (startDate.isBefore(endDate)) {
                    expectedTimeStamps.push(startDate.format('MMMM YYYY'));
                    startDate.add(1, 'months');
                }
                return expectedTimeStamps;
            }
            else {
                while (startDate.isBefore(endDate)) {
                    expectedTimeStamps.push(startDate.format('YYYY-MM-DD'));
                    startDate.add(1, 'days');
                }
                expectedTimeStamps.push(endDate.format('YYYY-MM-DD'));
            }
            expectedTimeStamps.shift()
            return expectedTimeStamps;
        },
        async formatGraphData() {
            // Initialize data and options
            this.data = {
                labels: [],
                series: []
            };
            this.legendNames = [];
            this.options = {
                stackBars: true,
                height: "350px",
                low: 0,
                axisY: {
                    onlyInteger: true
                },
                plugins: [
                    Chartist.plugins.tooltip(),
                    ChartistTooltip(),
                    Chartist.plugins.legend({
                        position: 'bottom',
                        legendNames: this.legendNames,
                    })
                ]
            };

            // Get expected timestamps based on the date range
            const expectedTimeStamps = this.getExpectedTimeStamps(
                this.filter.start_absolute,
                this.filter.end_absolute
            );
            this.data.labels = expectedTimeStamps;

            // Check if referralPathwaysBarChart data is available
            if (!this.referralPathwaysBarChart || !this.referralPathwaysBarChart.queries) {
                console.error("No data available in referralPathwaysBarChart");
                return;
            }

            // Process each query result
            this.referralPathwaysBarChart.queries.forEach(query => {
                query.results.forEach((result) => {
                    switch (result.name) {
                        case 'TotalReferralPathways':
                            // Ensure result.group_by and result.group_by[0].group exist
                            if (!result.group_by || !result.group_by[0] || !result.group_by[0].group) {
                                console.warn("Invalid group_by data in result:", result);
                                return;
                            }
                            // Format timestamps for the result values
                            const resultTimestamps = result.values.map(value => {
                                if ((this.filter.end_absolute - this.filter.start_absolute) <= oneDayMillis) {
                                    return moment(value[0]).format('h:mm a');
                                } else if ((this.filter.end_absolute - this.filter.start_absolute) >= oneMonthMillis) {
                                    return moment(value[0]).subtract(1, 'months').format('MMMM YYYY');
                                } else {
                                    return moment(value[0]).format('YYYY-MM-DD');
                                }
                            });
                            // Add legend name based on the tag
                            if (this.tag === 'callHandlerUuid') {
                                const agent = this.counsellorList.find(c => c.uuid === result.group_by[0].group.callHandlerUuid);
                                this.legendNames.push(agent ? agent.name : 'Unassigned*');
                            } else {
                                const unit = this.findUnitById(this.referralUnits, result.group_by[0].group[this.tag]);
                                const location = this.findLocationById(this.referralUnits, result.group_by[0].group[this.tag]);
                                if (unit) {
                                    this.legendNames.push(unit);
                                } else if (location) {
                                    this.legendNames.push(location);
                                } else {
                                    this.legendNames.push(result.group_by[0].group[this.tag]);
                                }
                            }
                            // Create a map of result values
                            const resultMap = result.values.reduce((map, value) => {
                                let timestamp;
                                if ((this.filter.end_absolute - this.filter.start_absolute) <= oneDayMillis) {
                                    timestamp = moment(value[0]).format('h:mm a');
                                } else if ((this.filter.end_absolute - this.filter.start_absolute) >= oneMonthMillis) {
                                    timestamp = moment(value[0]).subtract(1, 'months').format('MMMM YYYY');
                                } else {
                                    timestamp = moment(value[0]).format('YYYY-MM-DD');
                                }
                                map[timestamp] = value[1];
                                return map;
                            }, {});

                            // Create elements array for the series
                            const elements = expectedTimeStamps.map(timestamp => {
                                return resultMap[timestamp] || 0;
                            });

                            // Add elements to the series
                            this.data.series.push(elements);
                            break;

                        default:
                            break;
                    }
                });
            });
        },
        // Find a unit by id
        findUnitById(unitsArray, id) {
            for (const unit of unitsArray) {
                if (unit.id === id) {
                    return unit.name;
                }

                if (unit.subUnits && unit.subUnits.length > 0) {
                    const foundUnit = this.findUnitById(unit.subUnits, id);
                    if (foundUnit) {
                        return foundUnit;
                    }
                }
            }
            return null;
        },
        // Find a location by id
        findLocationById(unitsArray, locationId) {
            for (const unit of unitsArray) {
                if (unit.location && unit.location.length > 0) {
                    const foundLocation = unit.location.find((loc) => loc.id === locationId);
                    if (foundLocation) {
                        return foundLocation.name;
                    }
                }
                if (unit.subUnits && unit.subUnits.length > 0) {
                    const foundLocation = this.findLocationById(unit.subUnits, locationId);
                    if (foundLocation) {
                        return foundLocation;
                    }
                }
            }
            return null;
        }
    }
}
</script>
<style lang="scss" scoped>
.menu.dropdown {
    margin-bottom: 15px
}
</style>
