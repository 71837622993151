<template>
  <div class="col-12">
    <card
      class="card"
      v-if="!showReferralFollowup"
      title="Referral Pathways Table"
    >
      <table class="table">
        <thead>
          <th>Case Id</th>
          <th>Referral Unit</th>
          <th>Location</th>
          <th>Type</th>
          <th>Referred From</th>
          <th>Referred To</th>
          <th>Created</th>
          <th>More</th>
        </thead>
        <tbody>
          <!-- Loading State -->
          <tr v-if="isFetchingReferralPathways">
            <td colspan="8" style="text-align: center">
              <i class="fa fa-spinner fa-spin fa-fw fa-2x"></i>
            </td>
          </tr>

          <!-- No Data State -->
          <tr v-else-if="!referralPathwayDetails.length">
            <td colspan="8" style="text-align: center">
              No Referral Pathways Found
            </td>
          </tr>

          <!-- Data Rows -->
          <tr v-else v-for="(item, idx) in referralPathwayDetails" :key="idx">
            <td>{{ item.caseId }}</td>
            <td>{{ item.referralUnitName }}</td>
            <td>{{ item.referralLocationName }}</td>
            <td>{{ item.referralType }}</td>
            <td>{{ handleGetReferralUnit(item.fromReferralId) }}</td>
            <td>{{ handleGetReferralUnit(item.toReferralId) }}</td>
            <td>{{ formatTimestamp(item.created) }}</td>
            <td style="text-align: center">
              <button
                type="button"
                class="formButton"
                round
                @click="
                  handleShowTab(
                    item.referralUnitName,
                    item.referralLocationName,
                    item.referralUnitId,
                    item.referralLocationId,
                    item.caseId,
                    item.referralPathwayId
                  )
                "
              >
                <i class="ti-new-window icon"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </card>
    <div v-if="showReferralFollowup">
      <referral-pathway-followup
        :referralUnitName="referralUnitName"
        :referralLocationName="referralLocationName"
        :referralUnitId="referralUnitId"
        :referralLocationId="referralLocationId"
        :caseId="caseId"
        :referralPathwayId="referralPathwayId"
        @handleClose="handleCloseTab"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import ReferralPathwayFollowup from "./ReferralPathwayFollowup.vue";
export default {
  props: {
    referralPathwayDetails: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  data() {
    return {
      referralPathwayId: null, // unique id that store the referral pathway
      caseId: null,
      referralUnitName: null,
      referralLocationName: null,
      referralUnitId: null,
      referralLocationId: null,
      showReferralFollowup: false,
      isFetchingReferralPathways: false, // Simulate loading state
      referralPathways: [],
    };
  },
  computed: {
    ...mapState("cases", [
      "reportDetails",
      "allReferralUnits",
      "referralPathwayData",
    ]),
  },
  methods: {
    ...mapActions("cases", [
      "fetchReportDetails",
      "getReferralPathwaysByReferralId",
    ]),
    // Format timestamp to a readable date
    formatTimestamp(timestamp) {
      const date = new Date(parseInt(timestamp));
      return date.toLocaleString(); // Convert to local date and time
    },
    handleShowTab(
      referralUnitName,
      referralLocationName,
      referralUnitId,
      referralLocationId,
      caseId,
      referralPathwayId
    ) {
      this.referralUnitName = referralUnitName;
      this.referralLocationName = referralLocationName;
      this.referralUnitId = referralUnitId;
      this.referralLocationId = referralLocationId;
      this.caseId = caseId;
      this.showReferralFollowup = true;
      this.referralPathwayId = referralPathwayId;
    },
    handleCloseTab() {
      this.showReferralFollowup = false;
      this.referralUnit = null;
      this.referralLocation = null;
    },
    handleGetReferralUnit(referralId) {
      const referralUnit = this.referralPathwayDetails.find((unit) => {
        return unit.referralPathwayId === referralId;
      });
      return referralUnit ? referralUnit.referralUnitName : referralId;
    },
  },
  components: {
    ReferralPathwayFollowup,
  },
};
</script>

<style scoped>
.formButton {
  border: none;
  background-color: none;
}
.icon {
  color: rgb(96, 179, 179);
}
.table {
  width: 100%;
  border-collapse: collapse;
}

.table th,
.table td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
}

.table th {
  background-color: #f5f5f5;
  font-weight: bold;
}

.contact-number {
  cursor: pointer;
  color: blue;
  text-decoration: underline;
}
</style>
