<template>
    <div class="row">
        <div class="col-12 row">
            <p-button type="info" style="margin-left: 15px; margin-bottom: 15px; display: block" round
                @click.native.prevent="$router.push('/settings')">
                Back
            </p-button>
        </div>
        <div class="col-6">
            <card class="card create-referral-unit" title="Create Referral Unit"
                subTitle="Add Information about the referral unit">
                <form>
                    <div class="row col-md-12">
                        <div class="form-input-container">
                            <!-- <label for="name">Referral Unit Name</label> -->
                            <fg-input id="name" class="input" type="text" label="Referral Unit Name" required
                                v-model="referralUnit.name">
                            </fg-input>
                            <span v-if="error.name" class="text-danger">{{ error.name }}</span>
                        </div>
                    </div>

                    <div class="form-input-container multi-select">
                        <label for="name">Select Referral Location</label>
                        <v-select v-model="referralUnit.location" :options="lgaOfOccurrenceOptions" multiple
                            placeholder="" class="v-select"></v-select>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <p-button type="success" round @click.native.prevent="submitForm"
                                style="margin-left: auto; margin-top: 5px; display: block">
                                Create Unit
                            </p-button>
                        </div>
                    </div>
                </form>
            </card>
        </div>
        <div class="col-6">
            <create-referral-location />
        </div>
        <div class="col-6">
            <update-referral-unit />
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import swal from "sweetalert";
import CreateReferralLocation from "./CreateReferralLocation.vue";
import UpdateReferralUnit from "./UpdateReferralUnit.vue";
import { demoLocalGovermant } from "../../components/ReferralPathways.vue/pathwayDemoData";

export default {
    data() {
        return {
            referralUnit: {
                name: "",
                location: [],
            },
            error: {
                name: null,
                location: null,
            },
            selected: [],
            options: ["Option 1", "Option 2", "Option 3"],
        };
    },
    components: {
        UpdateReferralUnit,
        CreateReferralLocation,
        vSelect,
    },
    computed: {
        ...mapState("cases", ["isCreateReferralUnit, isUpdateReferralUnit"]),
        ...mapState("state", ["stateLga"]),
        lgaOfOccurrenceOptions() {
            const demoData = demoLocalGovermant.map((lg) => (lg.name));
            const localgoverment = this.stateLga.map((v) => {
                return v;
            });
            return !window.location.href.includes("localhost")
                ? localgoverment
                : demoData;
        },
    },
    methods: {
        ...mapActions("cases", ["createReferralUnit", "updateReferralUnit"]),
        validateForm() {
            let isValid = true;
            if (!this.referralUnit.name) {
                this.error.name = "Referral Unit Name is required";
                isValid = false;
            } else {
                this.error.name = null;
            }
            return isValid;
        },
        resetForm() {
            this.referralUnit = {
                name: "",
                location: [],
            };
        },
        async submitForm() {
            if (this.validateForm()) {
                try {
                    const { $router, referralUnit, createReferralUnit } = this;
                    swal({
                        title: "Complete Submission",
                        text: "Are You sure you want to create a Referral Unit?",
                        buttons: {
                            cancel: true,
                            confirm: true,
                        },
                    }).then(function (results) {
                        if (results) {
                            createReferralUnit({ units: [{ name: referralUnit.name, location: [...referralUnit.location], subUnits: [] }] });
                        }
                    }).then(() => {
                        this.$notify({
                            message: "Referral Unit Created Successfully",
                            icon: "ti-check-box",
                            horizontalAlign: "center",
                            verticalAlign: "top",
                            type: "success",
                        });
                        this.resetForm()
                    })
                } catch (error) {
                    this.$notify({
                        message: "Failed to create referral unit",
                        icon: "ti-alert",
                        horizontalAlign: "center",
                        verticalAlign: "top",
                        type: "danger",
                    });
                }
            }
        },
    },
};
</script>

<style scoped>
.create-referral-unit {
    display: block;
    z-index: 100;
}

.form-input-container {
    display: flex;
    flex-direction: column;
    align-items: left;
    width: 100%;
}

/* .form-input-container label{
    color: black;
} */
.form-input-container input {
    height: 35px;
    width: 100%;
    border: 1px solid rgb(214, 214, 214);
    border-radius: 6px;
    font-size: 16px;
    padding: 5px 10px;
}

#vs1__combobox {
    background-color: #FFFCF5 !important;
    border: none !important;
    height: 85px;
}

.v-select.v-select.vs--multiple.vs--searchable {
    background-color: #FFFCF5 !important;
    border: none !important;
    /* height: 85px; */
}
</style>
