<template>
  <div>
    <p-button
      type="info"
      style="margin-left: auto; margin-bottom: 15px; display: block"
      round
      @click.native.prevent="handleCloseForm"
      >Back</p-button
    >
    <card
      class="card"
      title="Referral Followup"
      subTitle="Add Information about the referrals unit for followup"
    >
      <form @submit.prevent="handleSubmit">
        <div v-if="error" class="alert alert-danger">
          {{ error || "Please select at least one referral pathway" }}
        </div>
        <div>
          <fg-input
            type="text"
            label="Referral Unit"
            :value="referralUnitName"
            v-model="referralUnitName"
            readonly
          >
          </fg-input>
        </div>
        <div>
          <fg-input
            type="text"
            label="Referral Location"
            :value="referralLocationName"
            v-model="referralLocationName"
            readonly
          >
          </fg-input>
        </div>

        <!-- Loop through units -->
        <div v-for="unit in units" :key="unit.name" class="form-group">
          <!-- Unit Checkbox -->
          <label>
            <input
              type="checkbox"
              v-model="selectedUnits"
              :value="unit.name"
              @change="handleUnitSelection(unit)"
            />
            {{ unit.name }}
          </label>
          <!-- Sub-Unit Selection (For any unit with subUnits) -->
          <div
            v-if="unit.subUnits.length > 0 && selectedUnits.includes(unit.name)"
          >
            <label>Select Sub-Unit:</label>
            <select
              v-model="selectedSubUnits[unit.name]"
              class="input-group form-control"
            >
              <option
                v-for="subUnit in unit.subUnits"
                :key="subUnit.name"
                :value="subUnit.id"
              >
                {{ subUnit.name }}
              </option>
            </select>

            <!-- Location Selection for Sub-Unit -->
            <div v-if="selectedSubUnits[unit.name]">
              <label>Select Location:</label>
              <select
                v-model="selectedLocations[unit.name]"
                class="input-group form-control"
              >
                <option
                  v-for="loc in getSubUnitLocations(
                    unit.name,
                    selectedSubUnits[unit.name]
                  )"
                  :key="loc.id"
                  :value="loc.id"
                >
                  {{ loc.name }}
                </option>
              </select>
            </div>
          </div>

          <!-- Location Selection for Units without Sub-Units -->
          <div
            v-else-if="
              selectedUnits.includes(unit.name) && unit.location.length > 0
            "
          >
            <label>Select Location:</label>
            <select
              v-model="selectedLocations[unit.name]"
              class="input-group form-control"
            >
              <option
                v-for="loc in unit.location"
                :key="loc.id"
                :value="loc.id"
              >
                {{ loc.name }}
              </option>
            </select>
          </div>
        </div>

        <p-button
          type="info"
          style="margin-left: auto; display: block"
          round
          @click.native.prevent="handleSubmitFollowup"
          >Submit</p-button
        >
      </form>
    </card>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import swal from "sweetalert";
export default {
  props: {
    referralPathwayId: {
      type: String,
      required: true,
    },
    caseId: {
      type: Number,
      required: true,
    },
    referralUnitName: {
      type: String,
      required: true,
    },
    referralLocationName: {
      type: String,
      required: true,
    },
    referralUnitId: {
      type: String,
      required: true,
    },
    referralLocationId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      error: null,
      units: [],
      finalData: null,
      selectedUnits: [],
      selectedSubUnits: {},
      selectedLocations: {},
    };
  },
  methods: {
    ...mapActions("cases", [
      "fetchAllReferralUnit",
      "caseReferralFollowup",
      "getReferralPathwaysByReferralId",
    ]),
    handleCloseForm() {
      this.$emit("handleClose");
    },
    getSubUnitLocations(unitName, subUnitId) {
      const unit = this.units.find((u) => u.name === unitName);
      if (unit) {
        const subUnit = unit.subUnits.find((sub) => sub.id === subUnitId);
        return subUnit ? subUnit.location : [];
      }
      return [];
    },
    handleUnitSelection(unit) {
      this.selectedUnits = [unit.name];
      if (!this.selectedUnits.includes(unit.name)) {
        // Reset sub-unit and location if unit is deselected
        this.$set(this.selectedSubUnits, unit.name, null);
        this.$set(this.selectedLocations, unit.name, null);
      }
    },
    handleSubmitFollowup() {
      const finalData = this.selectedUnits.map((unitName) => {
        const unit = this.units.find((u) => u.name === unitName);

        let dataEntry = {
          referralUnit: unit.id,
          location: this.selectedLocations[unit.name] || null,
        };

        // Add sub-unit if the unit has sub-units
        if (unit.subUnits.length > 0 && this.selectedSubUnits[unit.name]) {
          dataEntry.referralUnit = this.selectedSubUnits[unit.name];
          dataEntry.location = this.selectedLocations[unit.name] || null;
        }

        return dataEntry;
      });

      if (
        !this.caseId ||
        !this.referralPathwayId ||
        this.selectedUnits.length < 1
      ) {
        this.error = "Select a referral unit";
        return;
      }
      const followupData = {
        caseId: this.caseId,
        referralUnitId: finalData[0].referralUnit,
        referralLocationId: finalData[0].location,
        fromReferralId: this.referralPathwayId,
        referralType: "FollowUp",
      };
      this.error = null;

      const vm = this;
      swal({
        title: "Complete Submission",
        text: "Are You sure you want to add a followup for this case ?",
        buttons: {
          cancel: true,
          confirm: true,
        },
      }).then(function (results) {
        if (results) {
          // second variant
          vm.caseReferralFollowup(followupData);
          vm.$emit("handleClose");
        } else {
          // second variant
        }
      });
    },
  },
  computed: {
    ...mapState("cases", ["allReferralUnits"]),
  },
  mounted() {
    this.fetchAllReferralUnit()
      .then(() => {
        this.units = this.allReferralUnits.units;
      })
      .catch((error) => {
        console.error("Failed to fetch referral units:", error);
      });
  },
};
</script>

<style scoped>
/* Add your styles here */
.full {
  width: 100%;
}
</style>
