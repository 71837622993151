<template>
    <div class="col-12">
        <chart-card title="Referral Pathways Pie Chart" sub-title="Group Case by different categories"
            v-if="!isFetchingReferralPathwaysChart" :chart-data="data" :chart-options="options" chart-type="Pie"
            footer-text='One Day Ago (Default)'>
            <div class="row" slot="filter">
                <div class="col-12">
                    <div class="dropdown menu">
                        <button class="btn btn-round btn-info dropdown-toggle" type="button" id="dropdownMenuButton"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Filter
                        </button>
                        <div class="dropdown-menu dropdown-menu-left p4">
                            <form class="col-12" @submit.prevent>
                                <fg-input-dropdown type="text" label="Group By" placeholder="e.g Nature" required
                                    :options="tagOptions" v-model="tag">
                                </fg-input-dropdown>
                                <date-range-picker v-model="dateRange" ref="picker" :minDate="minDate"
                                    :maxDate="maxDate" opens="right" :locale-data="locale" @update="updateValues">
                                    <template v-slot:input="picker" style="min-width: 50px;">
                                        {{ picker.startDate | date }} - {{ picker.endDate | date }}
                                    </template>
                                </date-range-picker>
                                <br>
                                <p-button type="success" style="margin-left: auto; display: block;" round
                                    @click.native.prevent="fetchWithFilter">
                                    Submit
                                </p-button>
                                <br />
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="!data || data.series.length === 0" slot="empty">No Data Available</div>
        </chart-card>
    </div>
</template>
<script>
import {
    mapState,
    mapActions,
} from 'vuex';
import Chartist from 'chartist';
import ChartistTooltip from 'chartist-plugin-tooltips-updated';
require('chartist-plugin-legend');
import { ChartCard } from "@/components/index";
import { getCounsellor } from '@/utils/auth'
import {
    endOfTodayEndDate,
    isMoreThanAMonth,
    isOneDay,
    minDate,
    nowDate,
    oneDayAgoStartDate
} from "@/utils/utils";
import moment from "moment/moment";
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
export default {
    components: {
        ChartCard,
        DateRangePicker
    },
    /**
     * Chart data used to render stats, charts. Should be replaced with server data
     */
    data() {
        return {
            referralUnits: null,
            counsellor: null,
            minDate: minDate(),
            maxDate: nowDate(),
            tag: 'referralUnitId',
            dateRange: {
                startDate: oneDayAgoStartDate(),
                endDate: endOfTodayEndDate()
            },
            locale: {
                direction: 'ltr',
                format: 'mm-dd-yyyy',
                separator: ' to ',
                applyLabel: 'Apply',
                cancelLabel: 'Cancel',
                weekLabel: 'W',
                customRangeLabel: 'Custom Range',
                daysOfWeek: moment.weekdaysMin(),
                monthNames: moment.monthsShort(),
                firstDay: 1
            },
            tagOptions: [
                { key: 0, value: "referralUnitId", text: "Referral Unit" },
                { key: 1, value: "referralLocationId", text: "Referral Location" },
                { key: 2, value: "referralUnitType", text: "Referral Unit Type" },
            ],
            config: {
                format: '',
                useCurrent: false,
                showClear: true,
                showClose: true,
            },
            filter: {
                metrics: [
                    {
                        name: "TotalReferralPathways",
                        tags: {
                            workspaceId: []
                        },
                        group_by: [
                            {
                                name: "tag",
                                tags: [
                                    "referralUnitId"
                                ]
                            }
                        ],
                        aggregators: [
                            {
                                name: "sum",
                                sampling: {
                                    value: "1",
                                    unit: "hours"
                                },
                                align_end_time: true
                            }
                        ]
                    }
                ],
                plugins: [],
                cache_time: 0,
                start_absolute: oneDayAgoStartDate(),
                end_absolute: endOfTodayEndDate()
            },
            data: null,
            options: null,
        };
    },
    filters: {
        date(val) {
            return val ? moment(val).format('LL') : "";
        }
    },
    computed: {
        ...mapState('analytics', ['referralPathwaysChart', 'isFetchingReferralPathwaysChart']),
        ...mapState("auth", ["counsellorList", "isFetchingCounsellorList"]),
        ...mapState("cases", ["allReferralUnits"]),
    },
    watch: {
        async referralPathwaysChart(newValue) {
            await this.formatGraphData(newValue);
        },
        tag(newValue) {
            this.filter.metrics.map(metric => {
                metric.group_by[0].tags = [newValue]
                return metric
            })
        },
    },
    async mounted() {
        this.fetchAllReferralUnit()
            .then(() => {
                this.referralUnits = this.allReferralUnits.units;
                this.fetchWithFilter();
            })
            .catch((error) => {
                console.error("Failed to fetch referral units:", error);
            });
    },
    async created() {
        this.counsellor = getCounsellor();
    },
    methods: {
        ...mapActions("cases", ["fetchAllReferralUnit"]),
        ...mapActions('analytics', [
            'fetchReferralPathwaysChart',
        ]),
        updateValues({ startDate, endDate }) {
            this.filter.start_absolute = startDate;
            this.filter.end_absolute = endDate;
            this.fetchWithFilter();
        },
        async fetchWithFilter() {
            if (isOneDay(this.filter.start_absolute, this.filter.end_absolute)) {
                const stateDate = moment(this.filter.start_absolute)
                const endDate = moment(this.filter.end_absolute)
                this.filter.start_absolute = stateDate.startOf('day').valueOf()
                this.filter.end_absolute = endDate.endOf('day').valueOf()
                this.filter.metrics.map(metric => {
                    metric.aggregators[0].sampling = {
                        value: '1',
                        unit: 'hours',
                    };
                    metric.tags.workspaceId = [this.counsellor.workspaceId]
                    return metric
                })
            }
            else if (isMoreThanAMonth(this.filter.start_absolute, this.filter.end_absolute)) {
                const stateDate = moment(this.filter.start_absolute)
                const endDate = moment(this.filter.end_absolute)
                this.filter.start_absolute = stateDate.startOf('month').valueOf()
                this.filter.end_absolute = endDate.endOf('month').valueOf()
                this.filter.metrics.map(metric => {
                    metric.aggregators[0].sampling = {
                        value: '1',
                        unit: 'months',
                    };
                    metric.tags.workspaceId = [this.counsellor.workspaceId]
                    return metric
                })
            }
            else {
                const stateDate = moment(this.filter.start_absolute)
                const endDate = moment(this.filter.end_absolute)
                this.filter.start_absolute = stateDate.startOf('day').valueOf()
                this.filter.end_absolute = endDate.endOf('day').valueOf()
                this.filter.metrics.map(metric => {
                    metric.aggregators[0].sampling = {
                        value: '1',
                        unit: 'days',
                    };
                    metric.tags.workspaceId = [this.counsellor.workspaceId]
                    return metric
                })
            }

            const params = this.filter;
            Object.keys(params).forEach((key) => (params[key] === null || params[key] === '') && delete params[key]);
            await this.fetchReferralPathwaysChart(params);
        },
        async formatGraphData() {
            this.data = {
                labels: [],
                series: []
            };
            this.options = {
                labelOffset: 70,
                labelDirection: 'explode',
                plugins: [
                    Chartist.plugins.legend({
                        position: 'bottom',
                    }),
                    Chartist.plugins.tooltip(),
                    ChartistTooltip(),
                ]
            };

            this.referralPathwaysChart.queries.forEach(query => {
                query.results.forEach((result, idx) => {
                    switch (result.name) {
                        case 'TotalReferralPathways':
                            let label = '';
                            if (this.tag === 'callHandlerUuid') {
                                const agent = this.counsellorList.find(c => c.uuid === result.group_by[0].group[this.tag]);
                                label = agent ? agent.name : this.findUnitById(this.referralUnits, result.group_by[0].group[this.tag]) || this.findLocationById(this.referralUnits, result.group_by[0].group[this.tag]) || result.group_by[0].group[this.tag]
                            } else {
                                if (result.group_by && result.group_by[0].group) {
                                    label = this.findUnitById(this.referralUnits, result.group_by[0].group[this.tag]) || this.findLocationById(this.referralUnits, result.group_by[0].group[this.tag]) || result.group_by[0].group[this.tag]
                                }
                            }
                            this.data.labels.push(label || 'Unknown');

                            let totalValue = 0;
                            result.values.forEach((value) => {
                                totalValue += value[1];
                            });
                            this.data.series.push(totalValue);
                            break;
                        default:
                            break;
                    }
                });
            });
        },
        // Recursive function to find a unit by id
        findUnitById(unitsArray, id) {
            for (const unit of unitsArray) {
                if (unit.id === id) {
                    return unit.name;
                }
                if (unit.subUnits && unit.subUnits.length > 0) {
                    const foundUnit = this.findUnitById(unit.subUnits, id);
                    if (foundUnit) {
                        return foundUnit;
                    }
                }
            }
            return null;
        },
        // Recursive function to find a location by id
        findLocationById(unitsArray, locationId) {
            for (const unit of unitsArray) {
                if (unit.location && unit.location.length > 0) {
                    const foundLocation = unit.location.find((loc) => loc.id === locationId);
                    if (foundLocation) {
                        return foundLocation.name;
                    }
                }
                if (unit.subUnits && unit.subUnits.length > 0) {
                    const foundLocation = this.findLocationById(unit.subUnits, locationId);
                    if (foundLocation) {
                        return foundLocation;
                    }
                }
            }
            return null;
        }
    }
}
</script>
<style lang="scss" scoped>
.menu.dropdown {
    margin-bottom: 15px
}
</style>
